.PlayersContainer {
  padding: 0px;
  display: flex;
  flex-direction: row;
  overflow: auto;
  justify-content: center;
  margin: auto;
}

.Player {
  padding: 20px;
  border: 1px solid var(--color-border);
  min-width: 150px;
  display: flex;
  flex-direction: column;
  font-size: medium;
}

h4 {
  text-align: center;
}
