.ContentArea {
  display: flex;
  flex-direction: column;
  min-height: 60%;
  border: 1px solid  var(--color-background);
  overflow: auto;
  padding: 2px;
  justify-content: center;
}

.Footer {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top:20px;
}
