.GameController {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top:10px;
}

.GameControllerCard {
  border-radius: 5px;
  background-color: var(--color-background-secondary);
}

.GameControllerCardContentArea {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-around;
  padding: 30px;
  padding-bottom: 30px !important;
}

.GameControllerDivider {
  margin: 0px 10px 0px 10px;
}

.GameControllerCardTitle {
  display: flex;
  justify-content: flex-start;
  padding: 0px;
  padding-left: 20px;
  border-bottom: 1px solid var(--color-primary);
  font-weight: bold;
}

.GameControllerButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.GameControllerButton {
  display: flex;
  border-radius: 50%;
  background-color:  var(--color-background);
}
.GameControllerButton:hover {
  box-shadow: rgb(171 207 248) 0px 0px 0px 6px;
}

.GameControllerCardHeaderAverageContainer {
  display: flex;
  flex-direction: row;
  margin: 3px;
  padding-left: 30px;
}

.GameControllerCardHeaderAverageValue {
  margin-left: 5px;
  padding: 0px 5px 0px 5px;
  color: var(--color-warning);
  font-weight: bold;
}


.MuiCardHeader-action {
  margin: 0;
  padding-right: 5px;
}
